import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Process from '../../containers/the-primoko-difference/Process';
import { graphql } from 'gatsby';

const styles = (theme) => ({
	container: {
		[theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing.unit * 4,
			paddingRight: theme.spacing.unit * 4,
			paddingTop: theme.spacing.unit * 2,
			paddingBottom: theme.spacing.unit * 2
		  },
		  [theme.breakpoints.up("sm")]: {
			paddingLeft: theme.spacing.unit * 22,
			paddingRight: theme.spacing.unit * 22,
			paddingTop: theme.spacing.unit * 5,
			paddingBottom: theme.spacing.unit * 5
		  }
	},
	title: {
		[theme.breakpoints.down('sm')]: {
			...theme.typography.mtitle
		},
		[theme.breakpoints.up('sm')]: {
			...theme.typography.title
		}
	},
	paragraph: {
		[theme.breakpoints.down('sm')]: {
			...theme.typography.paragraph
		},
		[theme.breakpoints.up('sm')]: {
			...theme.typography.paragraph
		},
		'& p': {
			marginTop: '30px'
		}
	},
	space: {
		marginTop: '30px'
	},
	space2: {
		marginTop: '60px'
	},
	bold: {
		fontWeight: 'bold'
	},
	textBlue: {
		[theme.breakpoints.down('sm')]: {
			...theme.typography.paragraph,
			color: '#4198ff',
			fontWeight: '900'
		},
		[theme.breakpoints.up('sm')]: {
			...theme.typography.paragraph,
			color: '#4198ff',
			fontWeight: '900'
		},
		'&:hover': {
			color: '#4198ff',
			fontWeight: '900',
			textDecoration: 'underline'
		}
	}
});

const ThePrimokoDifferenceProcessPagePreviewTemplate = ({ data, classes }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Process frontmatter={frontmatter} />
	);
};

export default withStyles(styles)(ThePrimokoDifferenceProcessPagePreviewTemplate);

export const pageQuery = graphql`
	query ThePrimokoDifferenceProcessPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			html
			frontmatter {
				title
				image
				heading
				description
				list
				peace {
					heading
					description
				}
				testimonials {
					author
					quote
				}
			}
		}
	}
`;
