import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TabBar from "../../components/TabBar";
import { thePrimokoDiffernceFields } from "../../metadata";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import Layout from '../../components/layout';
import { Link } from 'gatsby';

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit * 4,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 22,
      marginRight: theme.spacing.unit * 22,
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 5
    }
  },
  subheading: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
  },
  article: {
    ...theme.typography.article,
    textTransform: "uppercase",
    paddingTop: theme.spacing.unit * 3
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  unordered: {
    paddingLeft: theme.spacing.unit * 3
  },
  list: {
    display: "list-item"
  },
  textBlue: {
    ...theme.typography.paragraph,
    color: "#4198ff",
    fontWeight: "700",
    fontSize: "16px",
    "&:hover": {
      color: "#4198ff",
      fontWeight: "700",
      textDecoration: "underline"
    }
  },
  spaceHeading: {
    marginTop: theme.spacing.unit * 5
  }
});

const Process = ({ classes, frontmatter, location }) => (
  <Layout location={location}>
    <React.Fragment>
      <TabBar fields={thePrimokoDiffernceFields} />
      <div className={classes.root}>
        <div className={classes.subheading}>The Primoko Process</div>
        <div className={classes.paragraph}>
          Every company has its process. Here’s ours.
        </div>
        {frontmatter.list.map((v, k) => (
          <div
            key={k}
            className={classNames(classes.paragraph)}
            dangerouslySetInnerHTML={{ __html: v }}
          />
        ))}
        <div className={classNames(classes.subheading, classes.spaceHeading)}>
          Peace of Mind
        </div>
        {frontmatter.peace.description.split("<br />").map((v, k) => (
          <Typography
            key={k}
            className={classNames(classes.paragraph, classes.space)}
          >
            {v}
          </Typography>
        ))}
        <Link to="/letstalk" className={classes.textBlue}>
          <div className={classes.spaceHeading}>Please let us know.</div>
        </Link>
      </div>
    </React.Fragment>
  </Layout>
);

export default withStyles(styles)(Process);
